/* Must use an interpolated color scale, which has a range of [0, 1] */
export function interpolateColors(
  dataLength: number,
  colorScale: (t: number) => string,
  colorRangeInfo: ColorRangeInfo
) {
  var { colorStart, colorEnd } = colorRangeInfo;
  var colorRange = colorEnd - colorStart;
  var intervalSize = colorRange / dataLength;
  var i, colorPoint;
  var colorArray = [];

  for (i = 0; i < dataLength; i++) {
    colorPoint = calculatePoint(i, intervalSize, colorRangeInfo);
    colorArray.push(colorScale(colorPoint));
  }

  return colorArray;
}

export function calculatePoint(i: number, intervalSize: number, colorRangeInfo: ColorRangeInfo) {
  const { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
  return useEndAsStart ? colorEnd - i * intervalSize : colorStart + i * intervalSize;
}
export interface ColorRangeInfo {
  colorStart: number;
  colorEnd: number;
  useEndAsStart: boolean;
}
