import ApiService from './apiService';

import { PatchUserAccount, UserAccount, UserAccountSettings } from '../models/userAccount';

class UserAccountService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_PRICING_SERVICE_URL);
  }

  getUserAccounts(): Promise<Array<UserAccount>> {
    return this.client.get('/user-accounts').then((response) => {
      return response.data;
    });
  }

  getUserAccountSettings(cognitoId: string): Promise<UserAccountSettings> {
    return this.client.get(`/user-accounts/${cognitoId}/settings`).then((response) => {
      return response.data;
    });
  }

  async createUserAccount(cognitoId: string, userAccount: UserAccount): Promise<void> {
    userAccount.updatedBy = cognitoId;
    return this.client.post('/user-accounts', userAccount);
  }

  async updateUserAccount(cognitoId: string, userAccount: UserAccount): Promise<void> {
    userAccount.updatedBy = cognitoId;
    return this.client.put(`/user-accounts/${userAccount.cognitoId}`, userAccount);
  }

  patchUserAccount(cognitoId: string, patch: PatchUserAccount): Promise<void> {
    return this.client.patch(`/user-accounts/${cognitoId}`, patch);
  }

  // key = lender name, value = count
  getCountOfFavoriteLendersByLender(): Promise<Record<string, number>> {
    return this.client.get('/user-accounts/favorite/counts').then((response) => {
      return response.data;
    });
  }
}

export default UserAccountService;
