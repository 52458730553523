import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';

import useEffectOnce from '../../hooks/useEffectOnce';
import ProductService from '../../services/productService';
import { GetDropdownTemplate, GetInputNumberTemplate, GetInputTextTemplate } from '../../utils/formTemplates';
import { ArmIndex, PaymentType, Product, ProductFamily, ProductSubfamily, ProductType } from '../../models/product';

const ProductModal = (props: { product: Product; onSave: () => void; onHide: () => void }) => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [productFamilies, setProductFamilies] = useState<Array<ProductFamily>>([]);
  const [productSubfamilies, setProductSubfamilies] = useState<Array<ProductSubfamily>>([]);
  const [productTypes, setProductTypes] = useState<Array<ProductType>>([]);
  const [armIndexes, setArmIndexes] = useState<Array<ArmIndex>>([]);
  const [paymentTypes, setPaymentTypes] = useState<Array<PaymentType>>([]);
  const [isNew] = useState<boolean>(!props.product.productId);

  const productService = new ProductService();

  const validationSchema = Yup.object({
    productId: Yup.number().nullable().required('Product id is required.').moreThan(0),
    productName: Yup.string().required('Product name is required.').trim(),
    productShortName: Yup.string().required('Product short name is required.').trim(),
    term: Yup.number().nullable().required('Term is required'),
    productFamily: Yup.object().required('Product family is required.'),
    productSubfamily: Yup.object().required('Product subfamily is required.'),
    productType: Yup.object().required('Product type is required.'),
    index: Yup.object()
      .nullable()
      .when('productType', {
        is: (productType: ProductType) => productType?.productTypeName === 'ARM',
        then: Yup.object().nullable().required('Index is required.'),
      }),
    paymentType: Yup.object().required('Payment type is required.'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: props.product,
    resolver: yupResolver(validationSchema),
  });

  useEffectOnce(() => {
    Promise.all([
      productService.getProductFamilies(),
      productService.getProductSubfamilies(),
      productService.getProductTypes(),
      productService.getIndexes(),
      productService.getPaymentTypes(),
    ])
      .then((results) => {
        setProductFamilies(results[0]);
        setProductSubfamilies(results[1]);
        setProductTypes(results[2]);
        setArmIndexes(results[3]);
        setPaymentTypes(results[4]);

        if (!props.product.paymentType) {
          reset({
            ...props.product,
            paymentType: results[4].find((pt) => pt.paymentTypeId === 'PI'),
          });
        }
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSave = (product: Product) => {
    setLoading(true);
    const addOrUpdate = isNew ? productService.createProduct(product) : productService.updateProduct(product);

    addOrUpdate
      .then(() => {
        props.onSave();
        props.onHide();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footerTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary p-button-text" onClick={props.onHide} />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-primary p-button"
          onClick={handleSubmit(onSave)}
          loading={loading}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        visible={true}
        style={{ width: '75vw' }}
        header="Product Details"
        modal
        footer={footerTemplate}
        onHide={props.onHide}
        className="p-fluid p-input-filled"
      >
        {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
        <Messages ref={errors} />
        <form>
          <div className="formgrid grid">
            <div className="field col-12 md:col-6">
              {GetInputNumberTemplate(control, formErrors, 'productId', 'Product Id', {
                disabled: !isNew,
              })}
            </div>
            <div className="field col-12 md:col-6">{GetInputNumberTemplate(control, formErrors, 'term', 'Term', {})}</div>
            <div className="field col-12 md:col-6">
              {GetInputTextTemplate(control, formErrors, 'productName', 'Name', {})}
            </div>
            <div className="field col-12 md:col-6">
              {GetInputTextTemplate(control, formErrors, 'productShortName', 'Short Name', {})}
            </div>
            <div className="field col-12 md:col-6">
              {GetDropdownTemplate(control, formErrors, 'productFamily', 'Family', productFamilies, {
                optionLabel: 'productFamilyName',
              })}
            </div>
            <div className="field col-12 md:col-6">
              {GetDropdownTemplate(control, formErrors, 'productSubfamily', 'Subfamily', productSubfamilies, {
                optionLabel: 'productSubfamilyName',
              })}
            </div>
            <div className="field col-12 md:col-6">
              {GetDropdownTemplate(control, formErrors, 'productType', 'Type', productTypes, {
                optionLabel: 'productTypeName',
                onChange: (e) => {
                  setValue('productType', e.value);
                  setValue('index', null);
                },
              })}
            </div>
            <div className="field col-12 md:col-6 ">
              {GetDropdownTemplate(control, formErrors, 'index', 'Index', armIndexes, {
                optionLabel: 'indexName',
                disabled: watch('productType')?.productTypeName !== 'ARM',
                showClear: true,
              })}
            </div>
            <div className="field col-12 md:col-6">
              {GetDropdownTemplate(control, formErrors, 'paymentType', 'Payment Type', paymentTypes, {
                optionLabel: 'paymentTypeName',
              })}
            </div>
          </div>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default ProductModal;
