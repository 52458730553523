import React, { useRef, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';

import useEffectOnce from '../hooks/useEffectOnce';
import LenderService from '../services/lenderService';
import SubscriptionService from '../services/subscriptionService';
import { Lender } from '../models/lender';
import { Subscription } from '../models/subscription';

const SubscriptionsPage = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const messages = useRef<any>(null);
  const [lenders, setLenders] = useState<Array<Lender>>([]);
  const [selectedLenders, setSelectedLenders] = useState<Array<string>>([]);

  const lenderService = new LenderService();
  const subscriptionService = new SubscriptionService();

  useEffectOnce(() => {
    Promise.all([lenderService.getLenders(), subscriptionService.getUserSubscriptions(user.username!)])
      .then((results) => {
        setLenders(results[0]);
        setSelectedLenders(results[1][0]?.lenderIds);
      })
      .catch((error) => {
        showError(error.message);
      });
  });

  const onSave = () => {
    subscriptionService
      .putSubscription(new Subscription(user.username!, selectedLenders))
      .then(() => {
        messages.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Subscriptions successfuly saved.',
          life: 10000,
        });
      })
      .catch((error) => {
        showError(error.message);
      });
  };

  const showError = (error: string) => {
    messages.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  return (
    <Card title="Manage Notification Subscriptions">
      <Messages ref={messages} />
      <div className="mb-2">Select lender(s) to receive a notification when they import prices</div>
      <MultiSelect
        id="lenders"
        options={lenders}
        optionLabel="lenderName"
        optionValue="lenderId"
        onChange={(e: { value: React.SetStateAction<string[]> }) => setSelectedLenders(e.value)}
        value={selectedLenders}
        placeholder="Select Lender(s)"
        maxSelectedLabels={3}
        filter
        className="mb-4"
      />
      <div>
        <Button label="Save" icon="pi pi-check" className="p-button-primary" onClick={onSave} />
      </div>
    </Card>
  );
};

export default SubscriptionsPage;
