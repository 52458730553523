import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { Button } from 'primereact/button';

import { ReactComponent as Arrow } from '../../assets/media/arrow.svg';
import { MenuItem } from '../../models/menuItem';

const AppMenuItem = (props: { item: MenuItem }) => {
  return (
    <React.Fragment>
      {!props.item.to && !props.item.url && <div className="section-title">{props.item.label}</div>}
      {props.item.url && (
        <div className="menu-item">
          <a href={props.item.url} className="flex align-items-center">
            <div className="menu-item-icon">
              <i className={`pi ${props.item.icon}`}></i>
            </div>
            <span>{props.item.label}</span>
          </a>
        </div>
      )}
      {props.item.to && (
        <div className="menu-item">
          <NavLink
            aria-label={props.item.label}
            to={props.item.to}
            end
            className="flex align-items-center"
          >
            <div className="menu-item-icon">
              <Arrow title="arrow" style={{ height: '1.5rem', width: '1.5rem' }} />
            </div>
            <span>{props.item.label}</span>
          </NavLink>
        </div>
      )}
    </React.Fragment>
  );
};

const AppSubmenu = (props: {
  level: number;
  items: Array<MenuItem>;
  onMenuItemClick: (item: MenuItem) => void;
}) => {
  const { user } = useAuthenticator((context) => [context.user]);

  const isAuthorized = (authorizedGroups?: Array<string>) => {
    if (!authorizedGroups) {
      return true;
    }

    const userGroups = user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'];
    return userGroups && authorizedGroups.some((g) => userGroups.includes(g));
  };

  return (
    <div>
      {props.items.map((item, i) => {
        if (!isAuthorized(item.authorizedGroups)) return <></>;

        return (
          <React.Fragment key={item.label}>
            {props.level === 0 && (
              <div className={'section'}>
                <AppMenuItem item={item} />
                <ul>
                  {item.items && (
                    <AppSubmenu
                      level={props.level + 1}
                      items={item.items}
                      onMenuItemClick={props.onMenuItemClick}
                    />
                  )}
                </ul>
              </div>
            )}
            {props.level !== 0 && (
              <li onClick={() => props.onMenuItemClick(item)}>
                <AppMenuItem item={item} />
                <ul>
                  {item.items && (
                    <AppSubmenu
                      level={props.level + 1}
                      items={item.items}
                      onMenuItemClick={props.onMenuItemClick}
                    />
                  )}
                </ul>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const AppMenu = (props: { items: Array<MenuItem>; onMenuItemClick: (item: MenuItem) => void }) => {
  const { signOut } = useAuthenticator((context) => [context.signOut]);

  return (
    <div className="layout-sidebar">
      <div className="layout-sidebar-main-container">
        <AppSubmenu level={0} items={props.items} onMenuItemClick={props.onMenuItemClick} />
      </div>
      <div className="layout-sidebar-footer flex justify-content-center">
        <Button
          label="Sign Out"
          className="p-button p-button-text flex flex-auto"
          onClick={signOut}
        />
      </div>
    </div>
  );
};

export default AppMenu;
