import { useRef, useState } from 'react';

import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';

import useEffectOnce from '../../hooks/useEffectOnce';
import UserAccountService from '../../services/userAccountService';
import UserAccountModal from '../../components/admin/UserAccountModal';
import { UserAccount } from '../../models/userAccount';

const UserAccountsPage = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [userAccounts, setUserAccounts] = useState<Array<UserAccount>>([]);
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [userAccount, setUserAccount] = useState<UserAccount | null>(null);

  const userAccountService = new UserAccountService();

  useEffectOnce(() => {
    loadUserAccounts();
  });

  const loadUserAccounts = () => {
    setLoading(true);
    userAccountService
      .getUserAccounts()
      .then((userAccounts) => {
        setUserAccounts(userAccounts);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onGlobalFilterChange = (value: string) => {
    const _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
  };

  const headerTemplate = () => {
    return (
      <div className="flex flex-row-reverse">
        <div>
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={filters['global'].value}
              onChange={(e) => onGlobalFilterChange(e.target.value)}
              placeholder="Search..."
            />
          </span>
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success"
            onClick={() => setUserAccount(new UserAccount())}
          />
        </div>
      </div>
    );
  };

  const updatedByTemplate = (userAccount: UserAccount) => {
    return userAccounts.find((u) => u.cognitoId === userAccount.updatedBy)?.name;
  };

  const actionBodyTemplate = (userAccount: UserAccount) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text mr-2"
          onClick={() => setUserAccount(userAccount)}
          tooltip="Edit User Account"
        />
      </>
    );
  };

  return (
    <Card title="User Accounts">
      <Messages ref={errors} />
      <DataTable
        value={userAccounts}
        header={headerTemplate}
        responsiveLayout="scroll"
        loading={loading}
        filters={filters}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        stripedRows
      >
        <Column field="cognitoId" header="Cognito Id" />
        <Column field="email" header="Email" />
        <Column field="name" header="Name" />
        <Column
          field="updatedDate"
          header="Updated Date"
          body={(userAccount) => userAccount.updatedDate!.toLocaleString()}
        />
        <Column field="updatedBy" header="Updated By" body={updatedByTemplate} />
        <Column header="Action" body={actionBodyTemplate} />
      </DataTable>

      {userAccount != null && (
        <UserAccountModal userAccount={userAccount} onSave={loadUserAccounts} onHide={() => setUserAccount(null)} />
      )}
    </Card>
  );
};

export default UserAccountsPage;
