import React, { useRef, useState } from 'react';

import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressBar } from 'primereact/progressbar';
import { Carousel } from 'primereact/carousel';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';

import CallService from '../../services/callService';
import { Employee } from '../../models/lender';

const ClickToCallOverlay = (props: { accountExcecutives: Array<Employee>; setRef: React.MutableRefObject<any> }) => {
  const messages = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>();

  const callService = new CallService();

  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const showError = (error: string) => {
    messages.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onCallAccountExecutive = (acctExec: string) => {
    if (!phoneNumber || phoneNumber === '') {
      showError('Missing phone number');
      return;
    }

    props.setRef.current!.hide();
    setLoading(true);
    callService
      .clickToCall({
        to: phoneNumber!,
        from: acctExec,
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const itemTemplate = (employee: Employee) => {
    return (
      <div>
        <div>
          {employee.firstName} {employee.lastName}
        </div>
        <div>Regional Account Executive</div>
        <div>{employee.phoneNumber.replace(phoneRegex, '($1) $2-$3')}</div>
        <div>{employee.email}</div>
        <div className="my-2">
          <InputMask
            id="phoneNumber"
            mask="(999) 999-9999"
            unmask={true}
            placeholder="Enter your phone number"
            className="mr-2"
            onChange={(e) => setPhoneNumber(e.value)}
          />
          <Button
            icon="pi pi-phone"
            iconPos="left"
            onClick={() => onCallAccountExecutive(employee.phoneNumber)}
            label="Call"
            className="p-button-success"
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Toast ref={messages} />
      <OverlayPanel ref={props.setRef} showCloseIcon id="overlay_panel">
        {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
        <div className="mt-0 mb-2" style={{ fontSize: '1.2rem' }}>
          <b>Click call to be connected to...</b>
        </div>
        <Carousel value={props.accountExcecutives} itemTemplate={itemTemplate} style={{ width: '380px' }}></Carousel>
        <div className="flex flex-column" style={{ color: 'var(--secondary-color)', fontSize: '1.1rem' }}>
          <div className="align-self-center">You will receive a call from (213) 668-5711</div>
          <div className="align-self-center">Please answer so we can connect you</div>
        </div>
      </OverlayPanel>
    </React.Fragment>
  );
};

export default ClickToCallOverlay;
