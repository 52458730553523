import React, { useRef, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';

import useEffectOnce from '../../hooks/useEffectOnce';
import UserAccountService from '../../services/userAccountService';
import LenderService from '../../services/lenderService';
import { UserAccount } from '../../models/userAccount';
import { Lender } from '../../models/lender';
import { GetDropdownTemplate, GetInputTextTemplate, GetMultiSelectTemplate } from '../../utils/formTemplates';

const UserAccountModal = (props: { userAccount: UserAccount; onSave: () => void; onHide: () => void }) => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [lenders, setLenders] = useState<Array<Lender>>([]);
  const [isNew] = useState<boolean>(!props.userAccount.cognitoId);
  const { user } = useAuthenticator((context) => [context.user]);

  const userAccountService = new UserAccountService();
  const lenderService = new LenderService();

  const validationSchema = Yup.object().shape({
    cognitoId: Yup.string().required('Cognito id is required.').trim(),
    email: Yup.string().email('Invalid email').required('Email is required.').trim(),
    name: Yup.string().required('Name is required.').trim(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: props.userAccount,
    resolver: yupResolver(validationSchema),
  });

  useEffectOnce(() => {
    lenderService
      .getLenders()
      .then((lenders) => {
        setLenders(lenders);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSave = (userAccount: UserAccount) => {
    setLoading(true);

    const addOrUpdate = isNew
      ? userAccountService.createUserAccount(user.username!, userAccount)
      : userAccountService.updateUserAccount(user.username!, userAccount);

    addOrUpdate
      .then(() => {
        props.onSave();
        props.onHide();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footerTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary p-button-text" onClick={props.onHide} />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-primary p-button"
          onClick={handleSubmit(onSave)}
          loading={loading}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        visible={true}
        style={{ width: '75vw' }}
        header="UserAccount Details"
        modal
        footer={footerTemplate}
        onHide={props.onHide}
        className="p-fluid p-input-filled"
      >
        {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
        <Messages ref={errors} />
        <div className="formgrid grid">
          <div className="field col-12">
            {GetInputTextTemplate(control, formErrors, 'cognitoId', 'Cognito Id', { disabled: !isNew })}
          </div>
          <div className="field col-12 md:col-6">
            {GetInputTextTemplate(control, formErrors, 'email', 'Email', { disabled: !isNew })}
          </div>
          <div className="field col-12 md:col-6">{GetInputTextTemplate(control, formErrors, 'name', 'Name', {})}</div>
          <div className="field col-12 md:col-6">
            {GetMultiSelectTemplate(control, formErrors, 'settings.favoriteLenders', 'Favorite Lender(s)', lenders, {
              optionLabel: 'lenderName',
              optionValue: 'lenderId',
            })}
          </div>
          <div className="field col-12 md:col-6">
            {GetDropdownTemplate(control, formErrors, 'settings.adminOfLender', 'Admin of Lender', lenders, {
              optionLabel: 'lenderName',
              optionValue: 'lenderId',
              showClear: true,
              defaultValue: '',
            })}
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default UserAccountModal;
