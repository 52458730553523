import ApiService from './apiService';

import { Lender } from '../models/lender';
import { PatchLenderRequest } from '../models/patchLenderRequest';

class LenderService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_PRICING_SERVICE_URL);
  }

  getLender(lenderId: string): Promise<Lender> {
    return this.client.get(`/lenders/${lenderId}`).then((response) => response.data);
  }

  getLenders(): Promise<Array<Lender>> {
    return this.client.get('/lenders').then((response) => {
      return response.data.sort((a: Lender, b: Lender) =>
        a.lenderName.toLowerCase() > b.lenderName.toLowerCase() ? 1 : -1
      );
    });
  }

  createLender(lender: Lender): Promise<void> {
    return this.client.post('/lenders', lender);
  }

  updateLender(lender: Lender): Promise<void> {
    return this.client.put(`/lenders/${lender.lenderId}`, lender);
  }

  deleteLender(lenderId: string): Promise<void> {
    return this.client.delete(`/lenders/${lenderId}`);
  }

  patchLender(lenderId: string, request: PatchLenderRequest) {
    return this.client.patch(`/lenders/${lenderId}`, request);
  }
}

export default LenderService;
