import { useRef, useState } from 'react';

import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import useEffectOnce from '../../hooks/useEffectOnce';
import RegionService from '../../services/regionService';
import RegionModal from '../../components/admin/RegionModal';
import { Region } from '../../models/region';

const RegionsPage = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [regions, setRegions] = useState<Array<Region>>([]);
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [region, setRegion] = useState<Region | null>(null);

  const regionService = new RegionService();

  useEffectOnce(() => {
    loadRegions();
  });

  const loadRegions = () => {
    setLoading(true);
    regionService
      .getRegions()
      .then((regions) => {
        setRegions(regions);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onGlobalFilterChange = (value: string) => {
    const _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
  };

  const onDelete = (region: Region) => {
    setLoading(true);
    regionService
      .deleteRegion(region.regionId!)
      .then(() => {
        loadRegions();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const headerTemplate = () => {
    return (
      <div className="flex flex-row-reverse">
        <div>
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={filters['global'].value}
              onChange={(e) => onGlobalFilterChange(e.target.value)}
              placeholder="Search..."
            />
          </span>
          <Button label="New" icon="pi pi-plus" className="p-button-success" onClick={() => setRegion(new Region())} />
        </div>
      </div>
    );
  };

  const actionBodyTemplate = (region: Region) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => setRegion(region)}
          tooltip="Edit Region"
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() =>
            confirmDialog({
              message: `Are you sure you want to delete this region: ${region.regionName}?`,
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => onDelete(region),
            })
          }
          tooltip="Delete Region"
        />
      </div>
    );
  };

  return (
    <Card title="Regions">
      <Messages ref={errors} />
      <ConfirmDialog />
      <DataTable
        value={regions}
        header={headerTemplate}
        responsiveLayout="scroll"
        loading={loading}
        filters={filters}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        stripedRows
      >
        <Column field="regionId" header="Region Id" />
        <Column field="regionName" header="Name" />
        <Column header="Actions" body={actionBodyTemplate} />
      </DataTable>

      {region != null && <RegionModal region={region} onSave={loadRegions} onHide={() => setRegion(null)} />}
    </Card>
  );
};

export default RegionsPage;
