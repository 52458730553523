const AppFooter = () => {
  const items = [
    {
      url: 'https://www.ratesboard.com',
      label: 'About Us',
      icon: null,
    },
    {
      url: 'https://www.linkedin.com/company/ratesboard',
      label: 'LinkedIn',
      icon: 'pi-linkedin',
    },
  ];

  return (
    <div className="layout-footer flex flex-row-reverse">
      <div>
        <ul className="flex align-items-center">
          {items.map((item) => {
            return (
              <li key={item.label}>
                <a href={item.url} className="flex align-items-center">
                  {item.icon && <i className={`pi ${item.icon} pr-1`}></i>}
                  <span>{item.label}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default AppFooter;
