import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
//import { Badge } from 'primereact/badge';

//import NotificationService from '../../services/notificationService';
//import { Notification } from '../../models/notification';
import { ReactComponent as MenuIcon } from '../../assets/media/menu.svg';
import { ReactComponent as Logo } from '../../assets/media/logo.svg';

const AppTopbar = (props: { onToggleMenuClick: () => void }) => {
  //const [_, setNotifications] = useState<Array<Notification>>([]);
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  const userMenu = useRef<Menu>(null);

  //const notificationService = new NotificationService();

  const userMenuItems = [
    /* {
      label: 'Subscriptions',
      command: () => {
        window.location.href = '/subscriptions';
      },
    }, */
    {
      label: 'Sign Out',
      command: () => {
        signOut();
      },
    },
  ];

  /* useEffect(() => {
    loadNotifications();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadNotifications = () => {
    notificationService.getUserNotifications(user.username!).then((notifications) => {
      setNotifications(notifications.filter((n) => n.status === 'Sent'));
    });
  }; */

  return (
    <div className="layout-topbar flex justify-content-between align-items-center">
      <div className="layout-topbar-left flex align-items-center">
        <Button
          className="p-button-lg p-button-rounded p-button-text"
          onClick={props.onToggleMenuClick}
          style={{ padding: '1rem' }}
        >
          <MenuIcon title="menu" />
        </Button>
        <Link to="/" className="layout-topbar-logo">
          <Logo title="ratesboard logo" />
        </Link>
      </div>

      <div>
        {/* <Button
          icon="pi pi-bell"
          className="p-button p-button-text"
          aria-label="Notification"
          onClick={() => {
            window.location.href = '/notifications';
          }}
        >
          {notifications.some(Boolean) && <Badge severity="danger"></Badge>}
        </Button> */}
        <Button
          icon="pi pi-user"
          className="p-button p-button-text"
          onClick={(event) => userMenu.current?.toggle(event)}
          aria-controls="popup_menu"
          aria-haspopup
        />
        <Menu model={userMenuItems} popup ref={userMenu} id="popup_menu" />
      </div>
    </div>
  );
};

export default AppTopbar;
