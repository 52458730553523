import ApiService from './apiService';

import { SearchPricingRequest } from '../models/searchPricingRequest';
import { SearchPricingResponse } from '../models/searchPricingResponse';

class PricingService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_PRICING_SERVICE_URL);
  }

  getLockTerms(): Promise<Array<number>> {
    return this.client.get('/pricing/lock-terms').then((response) => {
      return response.data.sort();
    });
  }

  searchPrices(request: SearchPricingRequest): Promise<SearchPricingResponse> {
    return this.client.post('/pricing/search', request).then((response) => {
      return response.data;
    });
  }
}

export default PricingService;
