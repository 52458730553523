import ApiService from './apiService';

import {
  ArmIndex,
  PaymentType,
  Product,
  ProductFamily,
  ProductSubfamily,
  ProductType,
} from '../models/product';

class ProductService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_PRICING_SERVICE_URL);
  }

  getProducts(): Promise<Array<Product>> {
    return this.client.get('/products').then((response) => {
      return response.data.sort((a: Product, b: Product) => {
        if (a.productFamily!.productFamilyId !== b.productFamily!.productFamilyId)
          return a.productFamily!.sortOrder > b.productFamily!.sortOrder ? 1 : -1;

        if (a.productSubfamily!.productSubfamilyId !== b.productSubfamily!.productSubfamilyId)
          return a.productSubfamily!.sortOrder > b.productSubfamily!.sortOrder ? 1 : -1;

        if (a.productType!.productTypeId !== b.productType!.productTypeId)
          return a.productType!.sortOrder > b.productType!.sortOrder ? 1 : -1;

        return a.term! > b.term! ? 1 : -1;
      });
    });
  }

  createProduct(product: Product): Promise<void> {
    return this.client.post('/products', product);
  }

  updateProduct(product: Product): Promise<void> {
    return this.client.put(`/products/${product.productId}`, product);
  }

  deleteProduct(productId: number): Promise<void> {
    return this.client.delete(`/products/${productId}`);
  }

  getProductFamilies(): Promise<Array<ProductFamily>> {
    return this.client.get('/products/families').then((response) => {
      return response.data;
    });
  }

  getProductSubfamilies(): Promise<Array<ProductSubfamily>> {
    return this.client.get('/products/subfamilies').then((response) => {
      return response.data;
    });
  }

  getProductTypes(): Promise<Array<ProductType>> {
    return this.client.get('/products/types').then((response) => {
      return response.data;
    });
  }

  getIndexes(): Promise<Array<ArmIndex>> {
    return this.client.get('/products/indexes').then((response) => {
      return response.data;
    });
  }

  getPaymentTypes(): Promise<Array<PaymentType>> {
    return this.client.get('/products/payment-types').then((response) => {
      return response.data;
    });
  }
}

export default ProductService;
