import axios, { AxiosInstance } from 'axios';
import { Auth } from 'aws-amplify';

abstract class ApiService {
  protected readonly client: AxiosInstance;
  private readonly isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

  constructor(baseUrl?: string) {
    this.client = axios.create({
      baseURL: baseUrl,
    });

    this.applyAuthInterceptor();
    this.applyDateInterceptor();
    this.applyErrorInterceptor();
  }

  private applyDateInterceptor(): void {
    this.client.interceptors.response.use((response) => {
      this.convertDates(response.data);
      return response;
    });
  }

  private applyAuthInterceptor(): void {
    this.client.interceptors.request.use(async (config) => {
      const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
      config.headers!.Authorization = `Bearer ${token}`;
      return config;
    });
  }

  private applyErrorInterceptor(): void {
    this.client.interceptors.response.use(
      (next) => {
        return Promise.resolve(next);
      },
      (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          throw new Error(`${error.response.data?.message || error.response.data || error.message}`);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          throw new Error(`A request was made but no response was received. ${error.message} `);
        } else {
          // Something happened in setting up the request that triggered an Error
          throw new Error(`${error.message}`);
        }
      }
    );
  }

  private convertDates(body: any): void {
    if (body === null || body === undefined || typeof body !== 'object') return body;

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value && typeof value === 'string' && this.isoDateFormat.test(value)) {
        body[key] = new Date(value);
      } else if (typeof value === 'object') {
        this.convertDates(value);
      }
    }
  }
}

export default ApiService;
