import { Subscription } from '../models/subscription';
import ApiService from './apiService';

class SubscriptionService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_PRICING_SERVICE_URL);
  }

  getUserSubscriptions(cognitoId: string): Promise<Array<Subscription>> {
    return this.client.get(`/subscriptions/${cognitoId}`).then((response) => {
      return response.data;
    });
  }

  putSubscription(subscription: Subscription): Promise<void> {
    return this.client.put(`/subscriptions`, subscription);
  }

  deleteSubscription(cognitoId: string): Promise<void> {
    return this.client.delete(`/subscriptions/${cognitoId}`);
  }
}

export default SubscriptionService;
