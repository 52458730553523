import { Control, Controller, FieldErrors, FieldPath, FieldValues } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { classNames } from 'primereact/utils';

export function GetInputTextTemplate<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  control: Control<TFieldValues>,
  errors: FieldErrors<TFieldValues>,
  field: TName,
  label: string,
  { disabled = false }: FormFieldOptions
) {
  return (
    <>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>
              {label}
            </label>
            <InputText
              id={field.name}
              value={field.value}
              onChange={field.onChange}
              className={classNames({ 'p-invalid': fieldState.error })}
              disabled={disabled}
            />
          </>
        )}
      />
      {GetFormErrorMessage(errors, field)}
    </>
  );
}

export function GetInputNumberTemplate<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  control: Control<TFieldValues>,
  errors: FieldErrors<TFieldValues>,
  field: TName,
  label: string,
  { disabled = false }: FormFieldOptions
) {
  return (
    <>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>
              {label}
            </label>
            <InputNumber
              id={field.name}
              value={field.value}
              onValueChange={(e) => field.onChange(e.value)}
              className={classNames({ 'p-invalid': fieldState.error })}
              disabled={disabled}
              useGrouping={false}
            />
          </>
        )}
      />
      {GetFormErrorMessage(errors, field)}
    </>
  );
}

export function GetInputMaskTemplate<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  control: Control<TFieldValues>,
  errors: FieldErrors<TFieldValues>,
  field: TName,
  label: string,
  { mask = undefined }: FormFieldOptions
) {
  return (
    <>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>
              {label}
            </label>
            <InputMask
              id={field.name}
              mask={mask}
              unmask={true}
              value={field.value}
              onChange={field.onChange}
              className={classNames({ 'p-invalid': fieldState.error })}
            />
          </>
        )}
      />
      {GetFormErrorMessage(errors, field)}
    </>
  );
}

export function GetDropdownTemplate<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  control: Control<TFieldValues>,
  errors: FieldErrors<TFieldValues>,
  field: TName,
  label: string,
  options: SelectItemOptionsType | undefined,
  {
    optionLabel = undefined,
    optionValue = undefined,
    onChange = null,
    showClear = false,
    disabled = false,
    defaultValue = null,
  }: FormFieldOptions
) {
  return (
    <>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>
              {label}
            </label>
            <Dropdown
              id={field.name}
              value={field.value}
              options={options}
              optionLabel={optionLabel}
              optionValue={optionValue}
              onChange={onChange ?? ((e) => field.onChange(e.value ?? defaultValue))}
              placeholder={`Select a ${label}`}
              filter
              className={classNames({ 'p-invalid': fieldState.error })}
              showClear={showClear}
              disabled={disabled}
            />
          </>
        )}
      />
      {GetFormErrorMessage(errors, field)}
    </>
  );
}

export function GetMultiSelectTemplate<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  control: Control<TFieldValues>,
  errors: FieldErrors<TFieldValues>,
  field: TName,
  label: string,
  options: SelectItemOptionsType | undefined,
  {
    optionLabel = undefined,
    optionValue = undefined,
    onChange = null,
    disabled = false,
    defaultValue = [],
  }: FormFieldOptions
) {
  return (
    <>
      <Controller
        name={field}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>
              {label}
            </label>
            <MultiSelect
              id={field.name}
              value={field.value}
              options={options}
              optionLabel={optionLabel}
              optionValue={optionValue}
              onChange={onChange ?? ((e) => field.onChange(e.value ?? defaultValue))}
              placeholder={`Select ${label}`}
              maxSelectedLabels={3}
              filter
              className={classNames({ 'p-invalid': fieldState.error })}
              showClear
              disabled={disabled}
            />
          </>
        )}
      />
      {GetFormErrorMessage(errors, field)}
    </>
  );
}

function GetFormErrorMessage<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(errors: FieldErrors<TFieldValues>, field: TName) {
  return errors[field] && <small className="p-error">{errors[field]?.message as string}</small>;
}

type FormFieldOptions = {
  mask?: string;
  optionLabel?: string;
  optionValue?: string;
  onChange?: ((e: any) => void) | null;
  disabled?: boolean;
  showClear?: boolean;
  defaultValue?: any;
};
