import ApiService from './apiService';

import { CallRequest } from '../models/callRequest';

class CallService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_PRICING_SERVICE_URL);
  }

  clickToCall(request: CallRequest): Promise<void> {
    return this.client.post(`/call/click-to-call`, request);
  }
}

export default CallService;
