import { Region } from './region';

export class Lender {
  lenderId: string | undefined = undefined;
  lenderName: string = '';
  lenderShortName: string = '';
  isActive: boolean = false;
  isClickToCallEnabled: boolean = false;
  isAccountExecutivesEnabled: boolean = false;
  regions: Array<Region> = [];
  accountExecutives: Array<Employee> = [];
}

export class Employee {
  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';
  email: string = '';
  assignedRegions: Array<string> = [];
}
