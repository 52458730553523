import { Notification } from '../models/notification';
import ApiService from './apiService';

class NotificationService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_PRICING_SERVICE_URL);
  }

  getUserNotifications(cognitoId: string): Promise<Array<Notification>> {
    return this.client.get(`/notifications/${cognitoId}`).then((response) => {
      return response.data;
    });
  }

  putNotification(cognitoId: string, notification: Notification): Promise<void> {
    return this.client.put(`/notifications/${cognitoId}`, notification);
  }
}

export default NotificationService;
