import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { classNames } from 'primereact/utils';

import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppTopbar from './AppTopbar';
import { MenuItem } from '../../models/menuItem';

const AppLayout = () => {
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const [menuActive, setMenuActive] = useState<boolean>(isDesktop());

  const menu: Array<MenuItem> = [
    {
      label: 'Broker',
      items: [
        {
          label: 'Compare Rates',
          to: '/broker/compare-rates',
        },
      ],
    },
    {
      label: 'Lender',
      authorizedGroups: ['Admin', 'Lender'],
      items: [
        {
          label: 'Compare Pricing History',
          to: '/lender/compare-pricing-history',
        },
        {
          label: 'Account Executives',
          to: '/lender/account-executives',
        },
      ],
    },
    {
      label: 'Admin',
      authorizedGroups: ['Admin'],
      items: [
        {
          label: 'Lenders',
          to: '/admin/lenders',
        },
        {
          label: 'Products',
          to: '/admin/products',
        },
        {
          label: 'Regions',
          to: '/admin/regions',
        },
        {
          label: 'User Accounts',
          to: '/admin/user-accounts',
        },
      ],
    },
    {
      label: 'Contact Us',
      items: [
        {
          label: 'Request Help',
          url: 'mailto:admin@ratesboard.com?subject=Help',
          icon: 'pi-envelope',
        },
        {
          label: 'Submit Feedback',
          url: 'mailto:admin@ratesboard.com?subject=Feedback',
          icon: 'pi-envelope',
        },
      ],
    },
  ];

  const onOverlayClick = () => {
    if (!isDesktop()) {
      setMenuActive(false);
    }
  };

  const onToggleMenuClick = () => {
    setMenuActive((prevState) => !prevState);
  };

  const onMenuItemClick = (item: MenuItem) => {
    if (!item.items && !isDesktop()) {
      setMenuActive(false);
    }
  };

  return (
    <div
      className={classNames('layout-wrapper p-input-filled', {
        'layout-sidebar-inactive': !menuActive,
      })}
    >
      <div
        className={classNames('layout-overlay', {
          'layout-overlay-active': menuActive,
        })}
        onClick={onOverlayClick}
      ></div>
      <AppTopbar onToggleMenuClick={onToggleMenuClick} />
      <AppMenu items={menu} onMenuItemClick={onMenuItemClick} />
      <div className="layout-content-container flex flex-column justify-content-between">
        <div className="flex-auto">
          <Outlet />
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default AppLayout;
