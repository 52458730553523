import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';

import useEffectOnce from '../../hooks/useEffectOnce';
import RegionService from '../../services/regionService';
import LenderService from '../../services/lenderService';
import { GetInputMaskTemplate, GetInputTextTemplate, GetMultiSelectTemplate } from '../../utils/formTemplates';
import { Employee, Lender } from '../../models/lender';
import { Region } from '../../models/region';

export class EmployeeModalData {
  lender: Lender = new Lender();
  employeeIndex: number = -1;
}

const EmployeeModal = (props: { data: EmployeeModalData; onSave: () => void; onHide: () => void }) => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [regions, setRegions] = useState<Array<Region>>();

  const regionService = new RegionService();
  const lenderService = new LenderService();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required').trim(),
    lastName: Yup.string().required('Last name is required').trim(),
    phoneNumber: Yup.string().required('Phone number is required').trim(),
    email: Yup.string().required('Email is required').trim(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues:
      props.data.employeeIndex === -1 ? new Employee() : props.data.lender.accountExecutives[props.data.employeeIndex],
    resolver: yupResolver(validationSchema),
  });

  useEffectOnce(() => {
    setLoading(true);
    regionService
      .getRegions()
      .then((regions) => {
        setRegions(regions);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSave = (employee: Employee) => {
    let _acctExecs = [...props.data.lender.accountExecutives];

    if (props.data.employeeIndex === -1) {
      _acctExecs.push(employee);
    } else {
      _acctExecs[props.data.employeeIndex] = employee;
    }

    setLoading(true);
    lenderService
      .patchLender(props.data.lender.lenderId!, {
        accountExecutives: _acctExecs,
      })
      .then(() => {
        props.onSave();
        props.onHide();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footerTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary p-button-text" onClick={props.onHide} />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-primary p-button"
          onClick={handleSubmit(onSave)}
          loading={loading}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        visible={true}
        style={{ width: '75vw' }}
        header="Employee Details"
        modal
        footer={footerTemplate}
        onHide={props.onHide}
        className="p-fluid p-input-filled"
      >
        {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
        <Messages ref={errors} />
        <form>
          <div className="formgrid grid">
            <div className="field col-6">{GetInputTextTemplate(control, formErrors, 'firstName', 'First Name', {})}</div>
            <div className="field col-6">{GetInputTextTemplate(control, formErrors, 'lastName', 'Last Name', {})}</div>
            <div className="field col-6">
              {GetInputMaskTemplate(control, formErrors, 'phoneNumber', 'Phone Number', { mask: '(999) 999-9999' })}
            </div>
            <div className="field col-6">{GetInputTextTemplate(control, formErrors, 'email', 'Email', {})}</div>
            <div className="field col-12">
              {GetMultiSelectTemplate(control, formErrors, 'assignedRegions', 'Region(s)', regions, {
                optionLabel: 'regionName',
                optionValue: 'regionId',
              })}
            </div>
          </div>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default EmployeeModal;
