import '@aws-amplify/ui-react/styles.css';
import './App.scss';

import { Navigate, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { useTheme, View, withAuthenticator } from '@aws-amplify/ui-react';
import AwsConfig from './aws-exports';

import SubscriptionsPage from './pages/SubscriptionsPage';
import NotificationsPage from './pages/NotificationsPage';

import LendersPage from './pages/Admin/LendersPage';
import ProductsPage from './pages/Admin/ProductsPage';
import RegionsPage from './pages/Admin/RegionsPage';
import UserAccountsPage from './pages/Admin/UserAccountsPage';

import CompareRatesPage from './pages/Broker/CompareRatesPage';

import ComparePricingHistoryPage from './pages/Lender/ComparePricingHistoryPage';
import AccountExecutivesPage from './pages/Lender/AccountExecutivesPage';

import ErrorPage from './pages/ErrorPage';

import AppLayout from './components/app/AppLayout';
import withGroupAuthorizer from './components/auth/withGroupAuthorizer';
import { ReactComponent as Logo } from './assets/media/logo.svg';

Amplify.configure(AwsConfig);

const formFields = {
  signUp: {
    name: {
      order: 1,
    },
    email: {
      order: 2,
    },
    password: {
      order: 3,
    },
    confirm_password: {
      order: 4,
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" backgroundColor={tokens.colors.background.primary}>
        <Logo title="ratesboard logo" style={{ width: '18rem' }} />
      </View>
    );
  },
};

const App = () => {
  return (
    <div>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="" element={<Navigate to="/broker/compare-rates" />} />
          <Route path="home" />
          <Route path="subscriptions" element={<SubscriptionsPage />} />
          <Route path="notifications" element={<NotificationsPage />} />

          <Route path="broker">
            <Route path="compare-rates" element={<CompareRatesPage />} />
          </Route>

          <Route path="lender">
            <Route
              path="compare-pricing-history"
              element={withGroupAuthorizer(ComparePricingHistoryPage, ['Admin', 'Lender'])}
            />
            <Route
              path="account-executives"
              element={withGroupAuthorizer(AccountExecutivesPage, ['Admin', 'Lender'])}
            />
          </Route>

          <Route path="admin">
            <Route path="lenders" element={withGroupAuthorizer(LendersPage, ['Admin'])} />
            <Route path="products" element={withGroupAuthorizer(ProductsPage, ['Admin'])} />
            <Route path="regions" element={withGroupAuthorizer(RegionsPage, ['Admin'])} />
            <Route
              path="user-accounts"
              element={withGroupAuthorizer(UserAccountsPage, ['Admin'])}
            />
          </Route>
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default withAuthenticator(App, {
  loginMechanisms: ['email'],
  signUpAttributes: ['email', 'name'],
  socialProviders: ['google'],
  formFields: formFields,
  components: components,
});
