import { useRef, useState } from 'react';

import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import useEffectOnce from '../../hooks/useEffectOnce';
import ProductService from '../../services/productService';
import ProductModal from '../../components/admin/ProductModal';
import { Product } from '../../models/product';

const ProductsPage = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<Product>>([]);
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [product, setProduct] = useState<Product | null>(null);

  const productService = new ProductService();

  useEffectOnce(() => {
    loadProducts();
  });

  const loadProducts = () => {
    setLoading(true);
    productService
      .getProducts()
      .then((products) => {
        setProducts(products.sort((a, b) => a.productId! - b.productId!));
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onGlobalFilterChange = (value: string) => {
    const _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
  };

  const onDelete = (product: Product) => {
    setLoading(true);
    productService
      .deleteProduct(product.productId!)
      .then(() => {
        loadProducts();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const headerTemplate = () => {
    return (
      <div className="flex flex-row-reverse">
        <div>
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={filters['global'].value}
              onChange={(e) => onGlobalFilterChange(e.target.value)}
              placeholder="Search..."
            />
          </span>
          <Button label="New" icon="pi pi-plus" className="p-button-success" onClick={() => setProduct(new Product())} />
        </div>
      </div>
    );
  };

  const actionBodyTemplate = (product: Product) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => setProduct(product)}
          tooltip="Edit Product"
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() =>
            confirmDialog({
              message: `Are you sure you want to delete this product: ${product.productName}?`,
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => onDelete(product),
            })
          }
          tooltip="Delete Product"
        />
      </div>
    );
  };

  return (
    <Card title="Products">
      <Messages ref={errors} />
      <ConfirmDialog />
      <DataTable
        value={products}
        header={headerTemplate}
        responsiveLayout="scroll"
        loading={loading}
        filters={filters}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        stripedRows
      >
        <Column field="productId" header="Product Id" />
        <Column field="productName" header="Name" />
        <Column field="productShortName" header="Short Name" />
        <Column field="term" header="Term" />
        <Column field="productFamily.productFamilyName" header="Product Family" />
        <Column field="productSubfamily.productSubfamilyName" header="Product Subfamily" />
        <Column field="productType.productTypeName" header="Product Type" />
        <Column field="index.indexName" header="Index" />
        <Column field="paymentType.paymentTypeName" header="Payment Type" />
        <Column header="Actions" body={actionBodyTemplate} />
      </DataTable>

      {product != null && <ProductModal product={product} onSave={loadProducts} onHide={() => setProduct(null)} />}
    </Card>
  );
};

export default ProductsPage;
