import React, { useEffect, useRef, useState } from 'react';
import { add, endOfDay, format, startOfDay } from 'date-fns';

import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';

const CalendarRange = (props: {
  id: string;
  dateRange: Date[] | undefined;
  onDateRangeChange: (dateRange: Date[] | undefined) => void;
}) => {
  const op = useRef<OverlayPanel>(null);
  const [dateRange, setDateRange] = useState<Date[] | undefined>(props.dateRange);

  const preseleectOptions = [
    {
      label: 'Past Week',
      days: 7,
    },
    {
      label: 'Past 2 Weeks',
      days: 14,
    },
    {
      label: 'Past 30 Days',
      days: 30,
    },
    {
      label: 'Past 60 Days',
      days: 60,
    },
  ];

  useEffect(() => {
    setDateRange(props.dateRange);
  }, [props.dateRange]);

  const setDateRangeToPastXDays = (days: number) => {
    const now = endOfDay(new Date());
    const range = [startOfDay(add(now, { days: -days })), now];
    setDateRange(range);
    props.onDateRangeChange(range);
  };

  const onCalendarChange = (value: Date | Date[] | undefined) => {
    let range = !value ? undefined : Array.isArray(value) ? value : [value];
    if (range) {
      range = range?.filter((d) => d);
      range.forEach((d, i) => {
        if (i === 0) {
          range![i] = startOfDay(d);
        } else if (i === 1) {
          range![i] = endOfDay(d);
        }
      });
    }
    setDateRange(range);
    props.onDateRangeChange(range);
  };

  return (
    <React.Fragment>
      <InputText
        readOnly
        autoComplete="off"
        inputMode="none"
        onFocus={(e) => op.current?.toggle(e)}
        value={dateRange?.map((d) => format(d, 'MM/dd/yy')).join('-')}
      ></InputText>
      <OverlayPanel ref={op}>
        <div className="flex flex-row">
          <div className="calendar-range-preselect-container flex flex-column">
            {preseleectOptions.map((option) => (
              <div
                key={option.label}
                onClick={() => setDateRangeToPastXDays(option.days)}
                className="calendar-range-preselect-option"
              >
                {option.label}
              </div>
            ))}
          </div>
          <Calendar inline selectionMode="range" value={dateRange} onChange={(e) => onCalendarChange(e.value)} />
        </div>
      </OverlayPanel>
    </React.Fragment>
  );
};

export default CalendarRange;
