export class Product {
  productId: number | undefined = undefined;
  productName: string = '';
  productShortName: string = '';
  term: number | undefined;
  productFamily: ProductFamily | undefined;
  productSubfamily: ProductSubfamily | undefined;
  productType: ProductType | undefined;
  index: ArmIndex | null | undefined;
  paymentType: PaymentType | undefined;
}

export interface ProductFamily {
  productFamilyId: string;
  productFamilyName: string;
  sortOrder: number;
}

export interface ProductSubfamily {
  productSubfamilyId: string;
  productSubfamilyName: string;
  sortOrder: number;
}

export interface ProductType {
  productTypeId: string;
  productTypeName: string;
  sortOrder: number;
}

export interface ArmIndex {
  indexId: string;
  indexName: string;
}

export interface PaymentType {
  paymentTypeId: string;
  paymentTypeName: string;
}
