import ApiService from './apiService';

import { Region } from '../models/region';

class RegionService extends ApiService {
  constructor() {
    super(process.env.REACT_APP_PRICING_SERVICE_URL);
  }

  getRegions(): Promise<Array<Region>> {
    return this.client.get('/regions').then((response) => {
      return response.data.sort((a: Region, b: Region) => (a.regionName > b.regionName ? 1 : -1));
    });
  }

  createRegion(region: Region): Promise<void> {
    return this.client.post('/regions', region);
  }

  updateRegion(region: Region): Promise<void> {
    return this.client.put(`/regions/${region.regionId}`, region);
  }

  deleteRegion(regionId: string): Promise<void> {
    return this.client.delete(`/regions/${regionId}`);
  }
}

export default RegionService;
