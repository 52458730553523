import { useRef, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { format } from 'date-fns';

import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';

import useEffectOnce from '../hooks/useEffectOnce';
import NotificationService from '../services/notificationService';
import { Notification } from '../models/notification';

const SubscriptionsPage = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const messages = useRef<any>(null);
  const [notifications, setNotifications] = useState<Array<Notification>>([]);

  const notificationService = new NotificationService();

  useEffectOnce(() => {
    loadNotifications();
  });

  const loadNotifications = () => {
    notificationService
      .getUserNotifications(user.username!)
      .then((results) => {
        setNotifications(
          results
            .filter((n) => n.status === 'Sent' || n.status === 'Read')
            .sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))
        );
      })
      .catch((error) => {
        showError(error.message);
      });
  };

  const updateNotificationAsRead = (notification: Notification) => {
    notification.status = 'Read';
    notificationService
      .putNotification(user.username!, notification)
      .then(() => {
        messages.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Notification successfully marked as read.',
          life: 10000,
        });
        loadNotifications();
      })
      .catch((error) => {
        showError(error.message);
      });
  };

  const showError = (error: string) => {
    messages.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  return (
    <Card title="Notifications">
      <Messages ref={messages} />
      {notifications &&
        notifications.map((notification) => {
          return (
            <div className="flex justify-content-between align-items-center pb-5">
              <span className="mr-5">{notification.title}</span>
              <div className="flex align-items-center">
                <span className="mr-5">{format(notification.createdDate, 'M/d/yy, h:mm a')}</span>
                {notification.status !== 'Read' && (
                  <Button
                    label="Mark As Read"
                    className="p-button-primary p-button-text"
                    onClick={() => {
                      updateNotificationAsRead(notification);
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
    </Card>
  );
};

export default SubscriptionsPage;
