export class Subscription {
  subscriptionId: string | undefined = undefined;
  cognitoId: string = '';
  eventType: string = 'PriceImport';
  lenderIds: Array<string> = [];

  constructor(cognitoId: string, lenderIds: Array<string>) {
    this.cognitoId = cognitoId;
    this.lenderIds = lenderIds;
  }
}
