import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { SelectButton } from 'primereact/selectbutton';

import useEffectOnce from '../../hooks/useEffectOnce';
import LenderService from '../../services/lenderService';
import RegionService from '../../services/regionService';
import { GetInputTextTemplate, GetMultiSelectTemplate } from '../../utils/formTemplates';
import { Lender } from '../../models/lender';
import { Region } from '../../models/region';

const LenderModal = (props: { lender: Lender; onSave: () => void; onHide: () => void }) => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [regions, setRegions] = useState<Array<Region>>();
  const [isNew] = useState<boolean>(!props.lender.lenderId);

  const lenderService = new LenderService();
  const regionService = new RegionService();

  const featureOptions = [
    { name: 'Off', value: false },
    { name: 'On', value: true },
  ];

  const validationSchema = Yup.object().shape({
    lenderId: Yup.string().uuid('Lender id should be a guid').trim(),
    lenderName: Yup.string().required('Lender name is required.').trim(),
    lenderShortName: Yup.string().required('Lender short name is required').trim(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: props.lender,
    resolver: yupResolver(validationSchema),
  });

  useEffectOnce(() => {
    setLoading(true);
    regionService
      .getRegions()
      .then((regions) => {
        setRegions(regions);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onSave = (lender: Lender) => {
    setLoading(true);

    const addOrUpdate = isNew ? lenderService.createLender(lender) : lenderService.updateLender(lender);

    addOrUpdate
      .then(() => {
        props.onSave();
        props.onHide();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footerTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary p-button-text" onClick={props.onHide} />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-primary p-button"
          onClick={handleSubmit(onSave)}
          loading={loading}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        visible={true}
        style={{ width: '75vw' }}
        header="Lender Details"
        modal
        footer={footerTemplate}
        onHide={props.onHide}
        className="p-fluid p-input-filled"
      >
        {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
        <Messages ref={errors} />
        <form>
          <div className="formgrid grid">
            <div className="field col-12">
              {GetInputTextTemplate(control, formErrors, 'lenderId', 'Lender Id', { disabled: !isNew })}
            </div>
            <div className="field col-12 md:col-6">
              {GetInputTextTemplate(control, formErrors, 'lenderName', 'Lender Name', {})}
            </div>
            <div className="field col-12 md:col-6">
              {GetInputTextTemplate(control, formErrors, 'lenderShortName', 'Short Name', {})}
            </div>
            <div className="field col-12">
              {GetMultiSelectTemplate(control, formErrors, 'regions', 'Region(s)', regions, {
                optionLabel: 'regionName',
              })}
            </div>
          </div>

          <Divider className="mt-0">
            <h3>Features</h3>
          </Divider>
          <div className="formgrid grid mx-3">
            <div className="field col-10">
              <label htmlFor="isActive">Is Active</label>
            </div>
            <div className="field col-2">
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => <SelectButton options={featureOptions} optionLabel="name" {...field} />}
              />
            </div>

            <div className="field col-10">
              <label htmlFor="isClickToCallEnabled">Is Click To Call Enabled</label>
            </div>
            <div className="field col-2">
              <Controller
                name="isClickToCallEnabled"
                control={control}
                render={({ field }) => <SelectButton options={featureOptions} optionLabel="name" {...field} />}
              />
            </div>

            <div className="field col-10">
              <label htmlFor="isAccountExecutivesEnabled">Is Account Executives Enabled</label>
            </div>
            <div className="field col-2">
              <Controller
                name="isAccountExecutivesEnabled"
                control={control}
                render={({ field }) => <SelectButton options={featureOptions} optionLabel="name" {...field} />}
              />
            </div>
          </div>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default LenderModal;
