export interface LenderPrice {
  importId: string;
  lenderId: string;
  lenderName: string;
  lenderShortName: string;
  channel: string;
  lockTerm: number;
  productId: number;
  regionId: string;
  createdDate: Date;
  updatedDate: Date;
  activeDate: Date;
  updatedBy: string;
  ratePrices: Array<RatePrice>;
  latest: boolean;

  productName: string;
  productShortName: string;
  selectedPrice: number | undefined;
  isFavorite?: boolean;
  numberOfPriceChangesToday: number;
}

export interface RatePrice {
  rate: number;
  price: number;

  priceChange?: number;
  priceChangeStatus?: PriceChangeStatus;
}

export enum PriceChangeStatus {
  None,
  Better,
  Worse,
}
