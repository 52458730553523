import ErrorBackground from './../assets/media/error-bg.jpg';

const ErrorPage = () => {
  return (
    <div
      className="flex flex-column justify-content-center p-4"
      style={{
        backgroundImage: `url(${ErrorBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh',
      }}
    >
      <h2 className="p-my-1" style={{ fontSize: '1.75rem' }}>
        Sorry we can't seem to find the page you're looking for.
      </h2>
      <span style={{ fontSize: '1.75rem' }}>
        There may be a misspelling in the URL entered, you may not have access to the page, or the
        page you are looking for may no longer exist.
      </span>
    </div>
  );
};

export default ErrorPage;
