import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { add, format, isToday } from 'date-fns';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import { Messages } from 'primereact/messages';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import useEffectOnce from '../../hooks/useEffectOnce';
import ProductService from '../../services/productService';
import RegionService from '../../services/regionService';
import PricingService from '../../services/pricingService';
import LenderService from '../../services/lenderService';
import UserAccountService from '../../services/userAccountService';
import { Product } from '../../models/product';
import { Region } from '../../models/region';
import { Employee, Lender } from '../../models/lender';
import { SearchPricingRequest } from '../../models/searchPricingRequest';
import { LenderPricesByProductId, SearchPricingResponse } from '../../models/searchPricingResponse';
import { LenderPrice, PriceChangeStatus } from '../../models/lenderPrice';

import { ReactComponent as TrendingUp } from '../../assets/media/trending-up.svg';
import { ReactComponent as TrendingDown } from '../../assets/media/trending-down.svg';
import ClickToCallOverlay from '../../components/broker/ClickToCallOverlay';

const CompareRatesPage = () => {
  const messages = useRef<any>(null);
  const op = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<Product>>([]);
  const [regions, setRegions] = useState<Array<Region>>([]);
  const [lockTerms, setLockTerms] = useState<Array<number>>([]);
  const [lenders, setLenders] = useState<Array<Lender>>([]);
  const [searchRequest, setSearchRequest] = useState<SearchPricingRequest>(new SearchPricingRequest());
  const [searchResults, setSearchResults] = useState<SearchPricingResponse>();
  const [selectedRates, setSelectedRates] = useState<Array<number>>([]);
  const [userFavorites, setUserFavorites] = useState<Array<string>>();
  const [favoriteCounts, setFavoriteCounts] = useState<Record<string, number>>();
  const [selectedAccountExecutives, setSelectedAccountExecutives] = useState<Array<Employee>>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthenticator((context) => [context.user]);

  const productService = new ProductService();
  const regionService = new RegionService();
  const pricingService = new PricingService();
  const lenderService = new LenderService();
  const userAccountService = new UserAccountService();

  useEffectOnce(() => {
    Promise.all([
      productService.getProducts(),
      regionService.getRegions(),
      pricingService.getLockTerms(),
      lenderService.getLenders(),
    ])
      .then((results) => {
        setProducts(results[0]);
        setRegions(results[1]);
        setLockTerms(results[2]);
        setLenders(results[3]);
        setRequestFromRouteParams();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });

    userAccountService.getUserAccountSettings(user.username!).then((result) => {
      setUserFavorites(result.favoriteLenders ?? []);
    });

    userAccountService.getCountOfFavoriteLendersByLender().then((result) => {
      setFavoriteCounts(result);
    });
  });

  const showError = (error: string) => {
    messages.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const setRequestFromRouteParams = () => {
    const request: SearchPricingRequest = {
      channel: 'Wholesale',
      regionId: searchParams.get('regionId') || undefined,
      productIds: searchParams.getAll('productIds').map(Number).filter(Boolean),
      lockTerm: searchParams.get('lockTerm') ? parseInt(searchParams.get('lockTerm')!) || undefined : undefined,
      dateRange: [],
      useLatest: true,
    };

    setSearchRequest(request);
  };

  const onInputChange = (val: any, propertyName: string) => {
    const _searchRequest = { ...searchRequest, [propertyName]: val };
    setSearchRequest(_searchRequest);

    if (Array.isArray(val)) {
      val.forEach((element: string, i: number) => {
        if (i === 0) {
          searchParams.set(propertyName, element);
        } else {
          searchParams.append(propertyName, element);
        }
      });
    } else {
      searchParams.set(propertyName, val);
    }
    setSearchParams(searchParams);
  };

  const onSearch = () => {
    if (
      !searchRequest.regionId ||
      !searchRequest.productIds ||
      searchRequest.productIds.length === 0 ||
      !searchRequest.lockTerm
    ) {
      showError('Unable to search: Please fill out all fields');
      return;
    }

    setLoading(true);
    const now = new Date();
    const pastRequest = {
      ...searchRequest,
      useLatest: false,
      dateRange: [now, add(now, { days: -7 })],
    };
    Promise.all([pricingService.searchPrices(searchRequest), pricingService.searchPrices(pastRequest)])
      .then((response) => {
        if (response[0].lenderPricesByProductIds.length === 0) {
          messages.current.show({
            severity: 'info',
            summary: 'Info:',
            detail: 'No results found.',
            life: 10000,
          });
          setSearchResults(undefined);
          setSelectedRates([]);
          return;
        }

        let _selectedRates: Array<number> = [];
        response[0].lenderPricesByProductIds.forEach((lpc, i) => {
          const product = products.find((p) => p.productId === lpc.productId);
          lpc.productName = product?.productName ?? '';
          lpc.productShortName = product?.productShortName ?? '';

          lpc.rates = [...new Set(lpc.lenderPrices.flatMap((lp: LenderPrice) => lp.ratePrices.map((rp) => rp.rate)))].sort();

          _selectedRates[i] = lpc.rates[Math.floor((lpc.rates.length - 1) / 2)];
          const matchProductId = response[1]?.lenderPricesByProductIds?.find((m) => m.productId === lpc.productId);
          lpc.lenderPrices.forEach((lp) => {
            lp.selectedPrice = lp.ratePrices.find((rp) => rp.rate === _selectedRates[i])?.price;
            lp.isFavorite = userFavorites?.some((f) => f === lp.lenderId);

            const matchLender = matchProductId?.lenderPrices
              ?.filter(
                (m) =>
                  m.lenderId === lp.lenderId &&
                  m.channel === lp.channel &&
                  m.lockTerm === lp.lockTerm &&
                  m.regionId === lp.regionId &&
                  !m.latest
              )
              .sort((a, b) => (a.activeDate > b.activeDate ? -1 : 1));

            lp.numberOfPriceChangesToday = matchLender?.filter((l) => isToday(l.activeDate)).length ?? 0;
            lp.ratePrices.forEach((rp) => {
              const matchRate = matchLender && matchLender[0]?.ratePrices?.find((x) => x.rate === rp.rate);
              if (!matchRate) {
                return;
              }

              rp.priceChange = rp.price - matchRate!.price;
              rp.priceChangeStatus =
                rp.priceChange > 0
                  ? PriceChangeStatus.Better
                  : rp.priceChange < 0
                  ? PriceChangeStatus.Worse
                  : PriceChangeStatus.None;
            });
          });
        });
        setSearchResults(response[0]);
        setSelectedRates(_selectedRates);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFavoriteClicked = (rowData: LenderPrice, isFavorite: boolean) => {
    const _userFavorites = isFavorite
      ? [...userFavorites!, rowData.lenderId]
      : userFavorites!.filter((f) => f !== rowData.lenderId);

    setLoading(true);
    userAccountService
      .patchUserAccount(user.username!, {
        favoriteLenders: _userFavorites,
      })
      .then(() => {
        setUserFavorites(_userFavorites);

        let favCount = (favoriteCounts && favoriteCounts[rowData.lenderId]) ?? 0;
        favCount = isFavorite ? favCount + 1 : favCount - 1;
        setFavoriteCounts({
          ...favoriteCounts,
          [rowData.lenderId]: favCount,
        });
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSelectedRateChange = (rate: number, index: number) => {
    let _selectedRates = [...selectedRates];
    _selectedRates[index] = rate;
    setSelectedRates(_selectedRates);

    let _result = { ...searchResults };
    _result.lenderPricesByProductIds!.forEach((lpc, i) => {
      lpc.lenderPrices.forEach((lp) => {
        lp.selectedPrice = lp.ratePrices.find((rp) => rp.rate === rate)?.price;
      });
    });
  };

  const tableHeaderTemplate = (lpc: LenderPricesByProductId, index: number) => {
    const ratesOptions = lpc.rates.map((r: number) => {
      return { label: r.toString(), value: r };
    });
    return (
      <>
        <div className="flex justify-content-between align-items-center">
          <h4>{lpc.productName}</h4>
          <div>
            <label htmlFor="rate" className="pr-2">
              Select Rate:
            </label>
            <Dropdown
              id="rate"
              value={selectedRates[index]}
              options={ratesOptions}
              onChange={(e) => onSelectedRateChange(e.value, index)}
              placeholder="Select a Rate"
              style={{ minWidth: '100px' }}
            />
          </div>
        </div>
      </>
    );
  };

  const lenderTemplate = (rowData: LenderPrice) => {
    return (
      <div className="flex align-items-center">
        <span className="pr-2 hidden md:table-cell">{rowData.lenderName}</span>
        <span className="pr-2 md:hidden">{rowData.lenderShortName || rowData.lenderName}</span>
      </div>
    );
  };

  const priceHeaderTemplate = (rate: number, lockTerm: number) => {
    return (
      <>
        <span className="hidden lg:table-cell">{`${rate.toFixed(3)}% @ ${lockTerm} days`}</span>
        <span className="lg:hidden">Price</span>
      </>
    );
  };

  const priceTemplate = (lenderPrice: LenderPrice, selectedRate: number) => {
    const rp = lenderPrice.ratePrices.find((rp) => rp.rate === selectedRate);

    let trendIcon = null;
    let trendDetails = null;

    switch (rp?.priceChangeStatus) {
      case PriceChangeStatus.Better:
        trendIcon = <TrendingUp className="trending-icon" style={{ color: '#2E6115' }} />;
        trendDetails = (
          <div className="details flex flex-row">
            <span style={{ color: '#2E6115' }}>+{rp.priceChange?.toFixed(3)}</span>
            <span className="hidden md:table-cell">&nbsp;improved</span>
          </div>
        );
        break;
      case PriceChangeStatus.Worse:
        trendIcon = <TrendingDown className="trending-icon" style={{ color: '#c63737' }} />;
        trendDetails = (
          <div className="details flex flex-row">
            <span style={{ color: '#c63737' }}>{rp.priceChange?.toFixed(3)}</span>
            <span className="hidden md:table-cell">&nbsp;worsened</span>
          </div>
        );
        break;
    }

    return (
      <>
        <div className="flex flex-column">
          <div className="flex align-items-center">
            <span>{lenderPrice.selectedPrice?.toFixed(3)}</span>
            {trendIcon}
          </div>
          {trendDetails}
        </div>
      </>
    );
  };

  const activeDateHeaderTemplate = () => {
    return (
      <>
        <span className="hidden lg:table-cell">Last Updated</span>
        <span className="lg:hidden">Status</span>
      </>
    );
  };

  const activeDateTemplate = (lenderPrice: LenderPrice) => {
    const isTodaysPricing = isToday(lenderPrice.activeDate);

    const statusStyle = {
      borderRadius: '2px',
      padding: '0.25rem 0.5rem',
      fontWeight: '500',
      fontSize: '12px',
      letterSpacing: '0.3px',
      background: isTodaysPricing ? '#c8e6c9' : '#ffcdd2',
      color: isTodaysPricing ? '#2E6115' : '#c63737',
    };

    if (isTodaysPricing) {
      return (
        <>
          <div className="hidden lg:table-cell">
            <div className="flex flex-column">
              <div>
                <span style={statusStyle}>TODAY, {format(lenderPrice.activeDate, 'h:mm a')}</span>
              </div>
              <div>
                {
                  <span className="details">
                    {lenderPrice.numberOfPriceChangesToday === 0 ? 'No' : lenderPrice.numberOfPriceChangesToday} price change
                    {lenderPrice.numberOfPriceChangesToday <= 1 ? '' : 's'} today
                  </span>
                }
              </div>
            </div>
          </div>
          <div className="hidden md:table-cell lg:hidden">
            <span style={statusStyle}>CURRENT</span>
            {lenderPrice.numberOfPriceChangesToday > 0 && (
              <span className="ml-1">({lenderPrice.numberOfPriceChangesToday})</span>
            )}
          </div>
          <div className="md:hidden">
            <div></div>
            <span style={statusStyle}>C</span>
            {lenderPrice.numberOfPriceChangesToday > 0 && (
              <span className="ml-1">({lenderPrice.numberOfPriceChangesToday})</span>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <span className="hidden lg:table-cell" style={statusStyle}>
            {format(lenderPrice.activeDate, 'M/d/yy, h:mm a')}
          </span>
          <span className="hidden md:table-cell lg:hidden" style={statusStyle}>
            PAST
          </span>
          <span className="md:hidden" style={statusStyle}>
            P
          </span>
        </>
      );
    }
  };

  const actionsTemplate = (lenderPrice: LenderPrice) => {
    const favCount = (favoriteCounts && favoriteCounts[lenderPrice.lenderId]?.toString()) ?? '0';
    const favButton = userFavorites?.some((f) => f === lenderPrice.lenderId) ? (
      <Button
        icon="pi pi-heart-fill"
        iconPos="left"
        onClick={() => onFavoriteClicked(lenderPrice, false)}
        label={favCount}
        tooltip="Remove from favorites"
        className="p-button-warning"
        disabled={!userFavorites}
      />
    ) : (
      <Button
        icon="pi pi-heart"
        iconPos="left"
        onClick={() => onFavoriteClicked(lenderPrice, true)}
        label={favCount}
        tooltip="Add to favorites"
        className="p-button-warning"
        disabled={!userFavorites}
      />
    );

    const lender = lenders.find((l) => l.lenderId === lenderPrice?.lenderId);
    const acctExecs = lender?.accountExecutives.filter((ae) => ae.assignedRegions.some((r) => r === lenderPrice?.regionId));

    return (
      <div className="flex align-items-center">
        {favButton}
        {process.env.REACT_APP_TWILIO_ENABLED === 'true' &&
          lender?.isClickToCallEnabled === true &&
          lender?.isAccountExecutivesEnabled === true &&
          (acctExecs?.length ?? 0) > 0 && (
            <Button
              icon="pi pi-phone"
              iconPos="left"
              onClick={(e) => {
                setSelectedAccountExecutives(acctExecs ?? []);
                op.current.toggle(e);
              }}
              tooltip="Call Account Exec"
              className="p-button-success ml-2"
            />
          )}
      </div>
    );
  };

  return (
    <>
      <Card title="Compare Rates">
        {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
        <div className="p-fluid">
          <Messages ref={messages} />
          <div className="formgrid grid">
            <div className="field col-12 md:col-6 lg:col">
              <label htmlFor="region">Region</label>
              <Dropdown
                id="region"
                value={searchRequest.regionId}
                options={regions}
                optionLabel="regionName"
                optionValue="regionId"
                onChange={(e) => onInputChange(e.value, 'regionId')}
                placeholder="Select a Region"
                filter
              />
            </div>
            <div className="field col-12 md:col-6 lg:col">
              <label htmlFor="productIds">Product(s)</label>
              <MultiSelect
                id="productIds"
                value={searchRequest.productIds}
                options={products}
                optionLabel="productName"
                optionValue="productId"
                onChange={(e) => onInputChange(e.value, 'productIds')}
                placeholder="Select Product(s)"
                maxSelectedLabels={3}
                filter
              />
            </div>
            <div className="field col-12 md:col-6 lg:col">
              <label htmlFor="lockTerm">Lock Term</label>
              <Dropdown
                id="lockTerm"
                value={searchRequest.lockTerm}
                options={lockTerms}
                onChange={(e) => onInputChange(e.value, 'lockTerm')}
                placeholder="Select a Lock Term"
              />
            </div>
            <div className="field col-12 md:col-6 lg:col">
              <label htmlFor="compare" style={{ opacity: 0 }}>
                placeholder
              </label>
              <Button id="compare" label="Compare" onClick={onSearch} />
            </div>
          </div>
        </div>
      </Card>
      {searchResults &&
        searchResults.lenderPricesByProductIds &&
        searchResults.lenderPricesByProductIds.map((lpc, i) => {
          return (
            <Card key={i} className="mt-5">
              <DataTable
                value={lpc.lenderPrices}
                responsiveLayout="scroll"
                header={tableHeaderTemplate(lpc, i)}
                multiSortMeta={[{ field: 'selectedPrice', order: -1 }]}
                sortMode="multiple"
                removableSort
              >
                <Column field="lenderName" header="Lender" body={lenderTemplate} sortable />

                <Column
                  field="selectedPrice"
                  header={priceHeaderTemplate(selectedRates[i], searchResults.lockTerm)}
                  body={(e) => priceTemplate(e, selectedRates[i])}
                  sortable
                />

                <Column field="activeDate" header={activeDateHeaderTemplate} body={activeDateTemplate} sortable />

                <Column header="Actions" body={actionsTemplate} />
              </DataTable>
            </Card>
          );
        })}

      <ClickToCallOverlay setRef={op} accountExcecutives={selectedAccountExecutives}></ClickToCallOverlay>
    </>
  );
};

export default CompareRatesPage;
