import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format, isDate, startOfDay, endOfDay } from 'date-fns';
import 'chartjs-adapter-date-fns';
import { interpolateCool } from 'd3-scale-chromatic';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import { Messages } from 'primereact/messages';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';

import useEffectOnce from '../../hooks/useEffectOnce';
import ProductService from '../../services/productService';
import RegionService from '../../services/regionService';
import PricingService from '../../services/pricingService';
import UserAccountService from '../../services/userAccountService';
import { Product } from '../../models/product';
import { Region } from '../../models/region';
import { SearchPricingRequest } from '../../models/searchPricingRequest';
import { LenderPrice } from '../../models/lenderPrice';
import { LenderPricesByProductId } from '../../models/searchPricingResponse';
import { ColorRangeInfo, interpolateColors } from '../../utils/colorGenerator';
import CalendarRange from '../../components/common/CalendarRange';

const ComparePricingHistoryPage = () => {
  const messages = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<Product>>([]);
  const [regions, setRegions] = useState<Array<Region>>([]);
  const [lockTerms, setLockTerms] = useState<Array<number>>([]);
  const [adminOfLender, setAdminOfLender] = useState<string>();
  const [searchRequest, setSearchRequest] = useState<SearchPricingRequest>(new SearchPricingRequest());
  const [result, setResult] = useState<any>();
  const [selectedRate, setSelectedRate] = useState<number>();
  const [graphOptions] = useState<any>({
    maintainAspectRatio: false,
    aspectRatio: 0.55,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          callback: function (_: any, index: any, ticks: any) {
            return format(ticks[index].value, 'M/d/yy');
          },
        },
      },
    },
  });

  const { user } = useAuthenticator((context) => [context.user]);
  const [searchParams, setSearchParams] = useSearchParams();

  const productService = new ProductService();
  const regionService = new RegionService();
  const pricingService = new PricingService();
  const userAccountService = new UserAccountService();

  const colorScale = interpolateCool;

  const colorRangeInfo: ColorRangeInfo = {
    colorStart: 0,
    colorEnd: 0.65,
    useEndAsStart: true,
  };

  useEffectOnce(() => {
    Promise.all([productService.getProducts(), regionService.getRegions(), pricingService.getLockTerms()])
      .then((results) => {
        setProducts(results[0]);
        setRegions(results[1]);
        setLockTerms(results[2]);
        setRequestFromRouteParams();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });

    userAccountService.getUserAccountSettings(user.username!).then((result) => {
      setAdminOfLender(result.adminOfLender);
    });
  });

  const showError = (error: string) => {
    messages.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const setRequestFromRouteParams = () => {
    let dateRange = searchParams
      .getAll('dateRange')
      .filter((d) => d && Date.parse(d))
      .map((d, i) => {
        if (i === 0) {
          return startOfDay(new Date(d));
        } else if (i === 1) {
          return endOfDay(new Date(d));
        }
        return new Date(d);
      })
      .slice(0, 2);

    const request: SearchPricingRequest = {
      channel: 'Wholesale',
      regionId: searchParams.get('regionId') || undefined,
      productIds: searchParams.getAll('productIds').map(Number).filter(Boolean),
      lockTerm: searchParams.get('lockTerm') ? parseInt(searchParams.get('lockTerm')!) || undefined : undefined,
      dateRange: dateRange,
      useLatest: false,
    };

    setSearchRequest(request);
  };

  const onInputChange = (val: any, propertyName: string) => {
    const _searchRequest = { ...searchRequest, [propertyName]: val };
    setSearchRequest(_searchRequest);

    if (Array.isArray(val)) {
      val.forEach((element: any, i: number) => {
        if (!element) {
          return;
        }

        element = isDate(element) ? format(element, 'MM-dd-yy') : element;
        if (i === 0) {
          searchParams.set(propertyName, element);
        } else {
          searchParams.append(propertyName, element);
        }
      });
    } else {
      searchParams.set(propertyName, val);
    }
    setSearchParams(searchParams);
  };

  const onProductIdChange = (val: number) => {
    const _searchRequest = { ...searchRequest };
    _searchRequest.productIds = [val];
    setSearchRequest(_searchRequest);

    searchParams.set('productIds', val.toString());
    setSearchParams(searchParams);
  };

  const onSearch = () => {
    if (
      !searchRequest.regionId ||
      !searchRequest.productIds ||
      searchRequest.productIds.length === 0 ||
      !searchRequest.lockTerm
    ) {
      showError('Unable to search: Please fill out all fields');
      return;
    }

    setLoading(true);
    pricingService
      .searchPrices(searchRequest)
      .then((response) => {
        const lpc = response.lenderPricesByProductIds[0];

        if (!lpc) {
          messages.current.show({
            severity: 'info',
            summary: 'Info:',
            detail: 'No results found.',
            life: 10000,
          });
          setResult(null);
          setSelectedRate(undefined);
          return;
        }

        const product = products.find((p) => p.productId === lpc.productId);
        const rates = [...new Set(lpc.lenderPrices.flatMap((lp: LenderPrice) => lp.ratePrices.map((rp) => rp.rate)))].sort();
        const selectedRate = rates[Math.floor((rates.length - 1) / 2)];

        const result = {
          datasets: getDataSetsFromRate(lpc, selectedRate),
          lpc: lpc,
          ratesOptions: rates.map((r: number) => {
            return { label: r.toString(), value: r };
          }),
          productName: product?.productName ?? '',
        };
        setResult(result);
        setSelectedRate(selectedRate);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDataSetsFromRate = (lpc: LenderPricesByProductId, selectedRate: number) => {
    const adminLender = lpc.lenderPrices.find((lp) => lp.lenderId === adminOfLender);
    const priceLenders = [
      ...new Set(lpc.lenderPrices.filter((lp) => lp.lenderId !== adminOfLender).flatMap((lp: LenderPrice) => lp.lenderName)),
    ].sort((a, b) => (a > b ? 1 : -1));
    if (adminLender) {
      priceLenders.unshift(adminLender?.lenderName);
    }
    const colors = interpolateColors(priceLenders.length, colorScale, colorRangeInfo);
    return priceLenders.map((pl, i) => {
      const data = lpc.lenderPrices
        .filter((lp) => lp.lenderName === pl && lp.ratePrices.some((rp) => rp.rate === selectedRate))
        .sort((a, b) => (a.activeDate > b.activeDate ? -1 : 1))
        .flatMap((lp) => {
          const activeDate = lp.activeDate;
          const price = lp.ratePrices.find((rp) => rp.rate === selectedRate)!.price;
          return {
            x: activeDate,
            y: price,
          };
        });
      const color =
        adminLender?.lenderName === pl
          ? `${window.getComputedStyle(document.body).getPropertyValue('--secondary-color')}`
          : colors[i];
      return {
        label: pl,
        data: data,
        showLine: true,
        lineTension: 0,
        fill: false,
        borderColor: color,
        backgroundColor: color,
      };
    });
  };

  const onSelectedRateChange = (val: number) => {
    setSelectedRate(val);
    let _result = { ...result };
    _result.datasets = getDataSetsFromRate(_result.lpc, val);
    setResult(_result);
  };

  return (
    <React.Fragment>
      <Card title="Compare Pricing History">
        {loading && <ProgressBar mode="indeterminate" className="mb-3" />}
        <div className="p-fluid">
          <Messages ref={messages} />
          <div className="formgrid grid">
            <div className="field col-12 md:col-6 lg:col">
              <label htmlFor="region">Region</label>
              <Dropdown
                id="region"
                value={searchRequest.regionId}
                options={regions}
                optionLabel="regionName"
                optionValue="regionId"
                onChange={(e) => onInputChange(e.value, 'regionId')}
                placeholder="Select a Region"
                filter
              />
            </div>
            <div className="field col-12 md:col-6 lg:col">
              <label htmlFor="productIds">Product</label>
              <Dropdown
                id="productIds"
                value={searchRequest.productIds[0]}
                options={products}
                optionLabel="productName"
                optionValue="productId"
                onChange={(e) => onProductIdChange(e.value)}
                placeholder="Select Product"
                filter
              />
            </div>
            <div className="field col-12 md:col-6 lg:col">
              <label htmlFor="lockTerm">Lock Term</label>
              <Dropdown
                id="lockTerm"
                value={searchRequest.lockTerm}
                options={lockTerms}
                onChange={(e) => onInputChange(e.value, 'lockTerm')}
                placeholder="Select a Lock Term"
              />
            </div>
            <div className="field col-12 md:col-6 lg:col">
              <label htmlFor="dateRange">Date Range</label>
              <CalendarRange
                id="dateRange"
                dateRange={searchRequest.dateRange}
                onDateRangeChange={(e) => onInputChange(e, 'dateRange')}
              />
            </div>
            <div className="field col-12 md:col lg:col">
              <label htmlFor="compare" style={{ opacity: 0 }}>
                placeholder
              </label>
              <Button id="compare" label="Compare" onClick={onSearch} />
            </div>
          </div>
        </div>
      </Card>
      {result && (
        <Card className="mt-5 p-datatable ">
          <div className="flex justify-content-between align-items-center p-datatable-header">
            <h4>{result?.productName}</h4>
            <div>
              <label htmlFor="rate" className="pr-2">
                Select Rate:
              </label>
              <Dropdown
                id="rate"
                value={selectedRate}
                options={result.ratesOptions}
                onChange={(e) => onSelectedRateChange(e.value)}
                placeholder="Select a Rate"
                style={{ minWidth: '100px' }}
              />
            </div>
          </div>
          <Chart type="line" data={result} options={graphOptions} />
        </Card>
      )}
    </React.Fragment>
  );
};

export default ComparePricingHistoryPage;
