export class UserAccount {
  cognitoId: string | undefined = undefined;
  email: string = '';
  name: string = '';
  phoneNumber: string = '';
  updatedDate: Date = new Date();
  updatedBy: string = '';
  settings: UserAccountSettings = new UserAccountSettings();
}

export class UserAccountSettings {
  favoriteLenders: Array<string> = [];
  adminOfLender: string = '';
}

export interface PatchUserAccount {
  favoriteLenders: Array<string>;
}
