import { useRef, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import useEffectOnce from '../../hooks/useEffectOnce';
import LenderService from '../../services/lenderService';
import UserAccountService from '../../services/userAccountService';
import { Employee, Lender } from '../../models/lender';
import EmployeeModal, { EmployeeModalData } from '../../components/lender/EmployeeModal';

const AccountExecutivesPage = () => {
  const errors = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [lender, setLender] = useState<Lender | null>(null);
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [employeeModalData, setEmployeeModalData] = useState<EmployeeModalData | null>(null);
  const { user } = useAuthenticator((context) => [context.user]);

  const lenderService = new LenderService();
  const userAccountService = new UserAccountService();

  useEffectOnce(() => {
    loadLender();
  });

  const loadLender = () => {
    setLoading(true);
    userAccountService
      .getUserAccountSettings(user.username!)
      .then((result) => {
        if (!!result.adminOfLender) {
          lenderService
            .getLender(result.adminOfLender)
            .then((lender) => {
              setLender(lender);
            })
            .catch((error) => {
              showError(error.message);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          showError('User is not setup as an admin of a lender');
        }
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showError = (error: string) => {
    errors.current.show({ severity: 'error', summary: 'Error:', detail: error, life: 10000 });
  };

  const onGlobalFilterChange = (value: string) => {
    const _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
  };

  const toggleAccountExecutives = (value: boolean) => {
    setLoading(true);
    lenderService
      .patchLender(lender!.lenderId!, {
        isAccountExecutivesEnabled: value,
      })
      .then(() => {
        loadLender();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDelete = (index: number) => {
    const _acctExecs = [...lender!.accountExecutives];
    _acctExecs.splice(index, 1);

    setLoading(true);
    lenderService
      .patchLender(lender!.lenderId!, {
        accountExecutives: _acctExecs,
      })
      .then(() => {
        loadLender();
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const headerTemplate = () => {
    return (
      <div className="flex flex-row-reverse">
        <div>
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={filters['global'].value}
              onChange={(e) => onGlobalFilterChange(e.target.value)}
              placeholder="Search..."
            />
          </span>
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success"
            onClick={() => setEmployeeModalData({ lender: lender!, employeeIndex: -1 })}
          />
        </div>
      </div>
    );
  };

  const phoneNumberTemplate = (employee: Employee) => {
    if (!employee.phoneNumber || employee.phoneNumber.length !== 10) return null;

    const areaCode = employee.phoneNumber.substring(0, 3);
    const digits = `${employee.phoneNumber.substring(3, 6)}-${employee.phoneNumber.substring(6, 10)}`;

    return `(${areaCode}) ${digits}`;
  };

  const regionsTemplate = (employee: Employee) => {
    return employee.assignedRegions.join(', ');
  };

  const actionBodyTemplate = (index: number, employee: Employee) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text"
          onClick={() => setEmployeeModalData({ lender: lender!, employeeIndex: index })}
          tooltip="Edit Employee"
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text"
          onClick={() =>
            confirmDialog({
              message: `Are you sure you want to delete this account executive: ${employee.firstName} ${employee.lastName}?`,
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => onDelete(index),
            })
          }
          tooltip="Delete Employee"
        />
      </div>
    );
  };

  return (
    <Card title="Account Executives">
      <Messages ref={errors} />
      <ConfirmDialog />
      <div className="flex align-items-center mb-2">
        <InputSwitch
          id="isAccountExecutivesEnabled"
          checked={lender?.isAccountExecutivesEnabled}
          onChange={(e) => toggleAccountExecutives(e.value)}
          className="mr-2"
        />
        <label htmlFor="isAccountExecutivesEnabled">Is Enabled</label>
      </div>
      <DataTable
        value={lender?.accountExecutives}
        header={headerTemplate}
        responsiveLayout="scroll"
        loading={loading}
        filters={filters}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        stripedRows
      >
        <Column field="firstName" header="First Name" />
        <Column field="lastName" header="Last Name" />
        <Column field="phoneNumber" header="Phone Number" body={phoneNumberTemplate} />
        <Column field="email" header="Email" />
        <Column field="assignedRegions" header="Regions" body={regionsTemplate} />
        <Column header="Actions" body={(e, props) => actionBodyTemplate(props.rowIndex, e)} />
      </DataTable>

      {employeeModalData != null && (
        <EmployeeModal data={employeeModalData} onSave={loadLender} onHide={() => setEmployeeModalData(null)} />
      )}
    </Card>
  );
};

export default AccountExecutivesPage;
